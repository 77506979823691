<template>
  <div class="base_info_box">
    <el-form label-position="left" label-width="80px" size="small">
      <el-form-item label="申请人">
        <div class="info">{{ data.adminName || "- -" }}</div>
      </el-form-item>
      <el-form-item label="申请时间">
        <div class="info">{{ data.createTime || "- -" }}</div>
      </el-form-item>
      <el-form-item label="账户名称" v-if="data.launchBO">
        <div class="info">{{ data.launchBO.account || "- -" }}</div>
      </el-form-item>
      <el-form-item label="产品名称" v-if="data.launchBO">
        <div class="info">
          {{ data.launchBO.launchProductBO.name || "- -" }}
        </div>
      </el-form-item>
      <el-form-item label="投放渠道" v-if="data.launchBO">
        <div class="info">
          {{ $tableDataHandle.launchType(data.launchBO.channelType) }}
        </div>
      </el-form-item>
      <el-form-item label="广告类型" v-if="data.launchBO">
        <div class="info">
          {{ $tableDataHandle.launchAdvertType(data.launchBO.adType) }}
        </div>
      </el-form-item>
      <el-form-item label="充值金额">
        <div class="info">{{ (data.rechargeAmount || 0) + "元" }}</div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  data() {
    return {
      data: {},
    };
  },
  components: {},
  created() {},
  methods: {
    getData(data) {
      this.data = data;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
  /* WebKit browsers 适配谷歌 */
  font-size: 12px !important;
}
.el-form-item {
  margin-bottom: 10px;
}
</style>
