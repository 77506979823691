<template>
    <div class="audit_detail_box">
        <el-form label-position="left" label-width="80px" size="small">
            <el-form-item label="审核结果">
                <div
                    class="info status"
                    v-if="
                        data.launchRechargeAuditBOList &&
                        data.launchRechargeAuditBOList.length > 0
                    "
                >
                    {{
                        data.launchRechargeAuditBOList[0].status == 1
                            ? '通过'
                            : '驳回'
                    }}
                </div>
            </el-form-item>
            <el-form-item label="审核备注">
                <div
                    class="info"
                    v-if="
                        data.launchRechargeAuditBOList &&
                        data.launchRechargeAuditBOList.length > 0
                    "
                >
                    {{ data.launchRechargeAuditBOList[0].remark || '- -' }}
                </div>
            </el-form-item>
            <el-form-item label="审核人">
                <div
                    class="info"
                    v-if="
                        data.launchRechargeAuditBOList &&
                        data.launchRechargeAuditBOList.length > 0
                    "
                >
                    {{ data.launchRechargeAuditBOList[0].adminName || '- -' }}
                </div>
            </el-form-item>
            <el-form-item label="审核时间">
                <div
                    class="info"
                    v-if="
                        data.launchRechargeAuditBOList &&
                        data.launchRechargeAuditBOList.length > 0
                    "
                >
                    {{ data.launchRechargeAuditBOList[0].createTime || '- -' }}
                </div>
            </el-form-item>
            <el-form-item label="收款账户">
                <div class="info" v-if="data.launchBO">
                    {{ data.launchBO.collectionAccount || '- -' }}
                </div>
            </el-form-item>
            <el-form-item label="打款账户">
                <div class="info" v-if="data.launchBO">
                    {{ data.launchBO.paymentAccount || '- -' }}
                </div>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    name: '',
    props: {},
    data() {
        return {
            data: {},
        };
    },
    components: {},
    created() {},
    methods: {
        getData(data) {
            this.data = data;
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.el-form-item {
    margin-bottom: 10px;
}
.status {
    width: 64px;
    height: 25px;
    border-radius: 13px;
    border: 1px solid #2370eb;
    text-align: center;
    line-height: 25px;
    color: #2370eb;
    margin-top: 3px;
}
</style>
