var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"audit_detail_box"},[_c('el-form',{attrs:{"label-position":"left","label-width":"80px","size":"small"}},[_c('el-form-item',{attrs:{"label":"审核结果"}},[(
                    _vm.data.launchRechargeAuditBOList &&
                    _vm.data.launchRechargeAuditBOList.length > 0
                )?_c('div',{staticClass:"info status"},[_vm._v(" "+_vm._s(_vm.data.launchRechargeAuditBOList[0].status == 1 ? '通过' : '驳回')+" ")]):_vm._e()]),_c('el-form-item',{attrs:{"label":"审核备注"}},[(
                    _vm.data.launchRechargeAuditBOList &&
                    _vm.data.launchRechargeAuditBOList.length > 0
                )?_c('div',{staticClass:"info"},[_vm._v(" "+_vm._s(_vm.data.launchRechargeAuditBOList[0].remark || '- -')+" ")]):_vm._e()]),_c('el-form-item',{attrs:{"label":"审核人"}},[(
                    _vm.data.launchRechargeAuditBOList &&
                    _vm.data.launchRechargeAuditBOList.length > 0
                )?_c('div',{staticClass:"info"},[_vm._v(" "+_vm._s(_vm.data.launchRechargeAuditBOList[0].adminName || '- -')+" ")]):_vm._e()]),_c('el-form-item',{attrs:{"label":"审核时间"}},[(
                    _vm.data.launchRechargeAuditBOList &&
                    _vm.data.launchRechargeAuditBOList.length > 0
                )?_c('div',{staticClass:"info"},[_vm._v(" "+_vm._s(_vm.data.launchRechargeAuditBOList[0].createTime || '- -')+" ")]):_vm._e()]),_c('el-form-item',{attrs:{"label":"收款账户"}},[(_vm.data.launchBO)?_c('div',{staticClass:"info"},[_vm._v(" "+_vm._s(_vm.data.launchBO.collectionAccount || '- -')+" ")]):_vm._e()]),_c('el-form-item',{attrs:{"label":"打款账户"}},[(_vm.data.launchBO)?_c('div',{staticClass:"info"},[_vm._v(" "+_vm._s(_vm.data.launchBO.paymentAccount || '- -')+" ")]):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }