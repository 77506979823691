<template>
  <div class="detail_box">
    <BaseInfo ref="baseInfo"></BaseInfo>
    <AuditDetail
      ref="auditDetail"
      v-if="tableRow.status > 1 && tableRow.status != 5"
    ></AuditDetail>
    <PayDetail
      ref="payDetail"
      v-if="tableRow.status > 2 && tableRow.status != 5"
    ></PayDetail>
  </div>
</template>

<script>
import { getOneRecharge } from "@/api/user/launch/pay.js";
import BaseInfo from "./components/baseInfo.vue";
import AuditDetail from "./components/auditDetail.vue";
import PayDetail from "./components/payDetail.vue";
export default {
  name: "",
  props: {},
  data() {
    return {
      tableRow: {},
    };
  },
  components: {
    BaseInfo,
    AuditDetail,
    PayDetail,
  },
  created() {},
  methods: {
    getData(row) {
      let data = {
        param: {
          id: row.id,
        },
      };
      getOneRecharge(data).then((res) => {
        if (res.code == 200) {
          this.tableRow = res.data;
          setTimeout(() => {
            this.$refs.baseInfo.getData(res.data);
            if (res.data.status > 1 && res.data.status != 5) {
              this.$refs.auditDetail.getData(res.data);
            }
            if (res.data.status > 2 && res.data.status != 5) {
              this.$refs.payDetail.getData(res.data);
            }
          }, 0);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
  /* WebKit browsers 适配谷歌 */
  font-size: 12px !important;
}
.detail_box {
  width: 100%;
  height: 100%;
  padding: 24px 35px;
  box-sizing: border-box;
}
</style>
