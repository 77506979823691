<template>
  <div class="pay_detail_box">
    <el-form label-position="left" label-width="80px" size="small">
      <el-form-item label="审核附件">
        <div
          class="info"
          v-if="
            data.launchRechargeAuditBOList &&
              data.launchRechargeAuditBOList.length > 0
          "
        >
          <el-image
            v-if="
              data.launchRechargeAuditBOList[1].attachmentUrl.indexOf('.jpg') !=
                -1 ||
                data.launchRechargeAuditBOList[1].attachmentUrl.indexOf(
                  '.png'
                ) != -1 ||
                data.launchRechargeAuditBOList[1].attachmentUrl.indexOf(
                  '.gif'
                ) != -1
            "
            style="width: 100px; height: 100px"
            :src="data.launchRechargeAuditBOList[1].attachmentUrl"
            :preview-src-list="[
              data.launchRechargeAuditBOList[1].attachmentUrl,
            ]"
          >
          </el-image>
          <span
            class="pdf"
            v-if="
              data.launchRechargeAuditBOList[1].attachmentUrl.indexOf('.pdf') !=
                -1 ||
                data.launchRechargeAuditBOList[1].attachmentUrl.indexOf(
                  '.PDF'
                ) != -1
            "
            @click="onPreview(data.launchRechargeAuditBOList[1].attachmentUrl)"
            style="cursor: pointer;"
          >
            <img src="@/img/pdfUrl.png" alt=""
          /></span>
          <span
            class="word"
            v-if="
              data.launchRechargeAuditBOList[1].attachmentUrl.indexOf('.doc') !=
                -1
            "
            @click="onPreview(data.launchRechargeAuditBOList[1].attachmentUrl)"
            style="cursor: pointer;"
          >
            <img src="@/img/word.png" alt="" />
          </span>
        </div>
      </el-form-item>
      <el-form-item label="审核备注">
        <div
          class="info"
          v-if="
            data.launchRechargeAuditBOList &&
              data.launchRechargeAuditBOList.length > 0
          "
        >
          {{ data.launchRechargeAuditBOList[1].remark || "- -" }}
        </div>
      </el-form-item>
      <el-form-item label="财务审核">
        <div
          class="info"
          v-if="
            data.launchRechargeAuditBOList &&
              data.launchRechargeAuditBOList.length > 0
          "
        >
          {{ data.launchRechargeAuditBOList[1].adminName || "- -" }}
        </div>
      </el-form-item>
      <el-form-item label="审核时间">
        <div
          class="info"
          v-if="
            data.launchRechargeAuditBOList &&
              data.launchRechargeAuditBOList.length > 0
          "
        >
          {{ data.launchRechargeAuditBOList[1].createTime || "- -" }}
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  data() {
    return {
      data: {},
    };
  },
  components: {},
  created() {},
  methods: {
    getData(data) {
      this.data = data;
    },
    onPreview(url) {
      if (url.indexOf(".doc") != -1 || url.indexOf(".docx") != -1) {
        window.open("https://view.officeapps.live.com/op/view.aspx?src=" + url);
      } else {
        window.open(url, "_blank");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
  /* WebKit browsers 适配谷歌 */
  font-size: 12px !important;
}
.el-form-item {
  margin-bottom: 10px;
}
</style>
