<template>
    <div class="declare_info">
        <p>
            <span>开票金额</span>
            <em>{{ invoiceInfo.invoiceAmount || '- -' }}</em>
            <el-button
                type="text"
                style="color: #2370eb"
                @click.stop="onCopy(invoiceInfo.invoiceAmount)"
                class="iconfont icon-fuzhi1"
            ></el-button>
        </p>
        <p>
            <span>开票类别</span>
            <em>{{
                invoiceInfo.invoiceCategory == 1
                    ? '电子发票'
                    : '纸质发票' || '- -'
            }}</em>
        </p>
        <p>
            <span>开票类型</span>
            <em>{{
                invoiceInfo.invoiceType == 1
                    ? '增值税普通发票'
                    : '增值税专用发票' || '- -'
            }}</em>
        </p>
        <p>
            <span>开票内容</span>
            <em>{{ '信息技术服务费' }}</em>
        </p>
        <p>
            <span>开票名称</span>
            <em>{{ invoiceInfo.invoiceName || '- -' }}</em>
            <el-button
                type="text"
                style="color: #2370eb"
                @click.stop="onCopy(invoiceInfo.invoiceName)"
                class="iconfont icon-fuzhi1"
            ></el-button>
        </p>
        <p>
            <span>税号</span>
            <em>{{ invoiceInfo.dutyNo || '- -' }}</em>
            <el-button
                type="text"
                style="color: #2370eb"
                @click.stop="onCopy(invoiceInfo.dutyNo)"
                class="iconfont icon-fuzhi1"
            ></el-button>
        </p>
        <p>
            <span>签署公司</span>
            <em>{{ invoiceInfo.contractB || '- -' }}</em>
            <el-button
                type="text"
                style="color: #2370eb"
                @click.stop="onCopy(invoiceInfo.contractB)"
                class="iconfont icon-fuzhi1"
            ></el-button>
        </p>
        <p v-if="invoiceInfo.invoiceCategory == 2">
            <span>收件人</span>
            <em>{{ invoiceInfo.receiveName || '- -' }}</em>
            <el-button
                type="text"
                style="color: #2370eb"
                @click.stop="onCopy(invoiceInfo.receiveName)"
                class="iconfont icon-fuzhi1"
            ></el-button>
        </p>
        <p v-if="invoiceInfo.invoiceCategory == 2">
            <span>收件电话</span>
            <em>{{ invoiceInfo.receivePhone || '- -' }}</em>
            <el-button
                type="text"
                style="color: #2370eb"
                @click.stop="onCopy(invoiceInfo.receivePhone)"
                class="iconfont icon-fuzhi1"
            ></el-button>
        </p>
        <p v-if="invoiceInfo.invoiceCategory == 2">
            <span>收件地址</span>
            <em>{{ invoiceInfo.receiveAddress || '- -' }}</em>
            <el-button
                type="text"
                style="color: #2370eb"
                @click.stop="onCopy(invoiceInfo.receiveAddress)"
                class="iconfont icon-fuzhi1"
            ></el-button>
        </p>
        <p v-if="invoiceInfo.invoiceCategory == 1">
            <span>收件邮箱</span>
            <em>{{ invoiceInfo.receiveEmail || '- -' }}</em>
            <el-button
                type="text"
                style="color: #2370eb"
                @click.stop="onCopy(invoiceInfo.receiveEmail)"
                class="iconfont icon-fuzhi1"
            ></el-button>
        </p>
        <p v-if="invoiceInfo.invoiceType == 2">
            <span>开户银行</span>
            <em>{{ invoiceInfo.depositBank || '- -' }}</em>
        </p>
        <p v-if="invoiceInfo.invoiceType == 2">
            <span>银行账户</span>
            <em>{{ invoiceInfo.bankAccount || '- -' }}</em>
        </p>
        <p v-if="invoiceInfo.invoiceType == 2">
            <span>公司电话</span>
            <em>{{ invoiceInfo.companyPhone || '- -' }}</em>
        </p>
        <p v-if="invoiceInfo.invoiceType == 2">
            <span>注册地址</span>
            <em>{{ invoiceInfo.registerAddress || '- -' }}</em>
        </p>
        <p>
            <span>备注</span>
            <em>{{ invoiceInfo.remark || '- -' }}</em>
        </p>
    </div>
</template>

<script>
import { declareUpdate, declareGet } from '@/api/cost/declare.js';
import { contracGet } from '@/api/contr/contr.js';
export default {
    name: '',

    data() {
        return {
            btnP: {},
            update: false,
            form: {
                payWay: '',
            },
            routePath: this.$route.path,
            invoiceInfo: {},
        };
    },
    components: {},
    created() {},
    methods: {
        getData(data) {
            this.invoiceInfo = data;
        },
        toUpdate() {
            this.update = !this.update;
            if (this.update) {
                this.form.payWay =
                    JSON.parse(JSON.stringify(this.declareDetail.payWay)) || '';
            }
        },
        declareGet(id) {
            let data = {
                param: {
                    id,
                },
            };
            return declareGet(data);
        },
        async declareUpdate() {
            let res = await this.declareGet(this.declareDetail.id);
            if (res.code == 200) {
                if (res.data.status == 4) {
                    let data = {
                        param: {
                            id: this.declareDetail.id,
                            payWay: this.form.payWay,
                            status: this.declareDetail.status,
                        },
                    };
                    declareUpdate(data).then((res) => {
                        if (res.code == 200) {
                            this.declareDetail.payWay = this.form.payWay;
                            this.update = false;
                        }
                    });
                } else {
                    this.update = false;
                    return this.$message.error(
                        '关联申报已更新，无法变更支付方式'
                    );
                }
            }
        },
        // 复制
        onCopy(item) {
            let url = item;
            let oInput = document.createElement('input');
            oInput.value = url;
            document.body.appendChild(oInput);
            oInput.select(); // 选择对象;
            document.execCommand('Copy'); // 执行浏览器复制命令
            this.$message({
                message: '复制成功',
                type: 'success',
            });
            oInput.remove();
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.declare_info {
    margin-bottom: 16px;
    p {
        flex: 1;
        color: #333333;
        display: flex;
        padding-left: 15px;
        align-items: center;
        span {
            color: #666666;
            display: block;
            width: 56px;
            white-space: nowrap;
            margin-right: 28px;
        }
        em {
            font-weight: 600;
            font-style: normal;

            i {
                font-size: 16px;
                color: #2370eb;
                cursor: pointer;
                vertical-align: -1px;
                margin-left: 10px;
            }
        }
        .status {
            border-radius: 13px;
            border: 1px solid #2370eb;
            color: #2370eb;

            width: 64px;
            height: 24px;
            display: block;
            flex: none;
            text-align: center;
            line-height: 24px;
        }
        .el-select {
            margin-top: -5px;
            margin-bottom: 0;
        }
        button {
            height: 25px;
            line-height: 0px;
            padding: 5px 12px;
            margin-left: 5px;
        }

        button + button {
            margin-left: 5px;
        }
    }

    p + p {
        margin-top: 13px;
    }
}
</style>
