<template>
    <div class="declare_info">
        <p>
            <span>所在部门</span>
            <em v-if="reimbDetail.departmentAdminDOList">
                <el-tooltip placement="top">
                    <div slot="content">
                        <p
                            style="line-height: 2"
                            v-for="item in reimbDetail.departmentAdminDOList"
                            :key="item.id"
                        >
                            {{ item.departmentName }}
                        </p>
                    </div>
                    <em>{{ returnName() }}</em>
                </el-tooltip>
            </em>
            <!-- <em v-if="reimbDetail.department">
                {{ reimbDetail.department.departmentName }}
            </em> -->
        </p>
        <p>
            <span>提交日期</span>
            <em>{{ $tableDataHandle.createTime(reimbDetail) }}</em>
        </p>
        <p>
            <span>客户</span>
            <em v-if="reimbDetail.customer">{{
                reimbDetail.customer.companyName || '- -'
            }}</em>
            <em v-else>- -</em>
        </p>
        <p>
            <span>客户名称</span>
            <em>{{ reimbDetail.customerName || '- -' }}</em>
        </p>
        <p>
            <span>报销状态</span>
            <em class="status">{{ $cost.reimbStatus(reimbDetail.status) }}</em>
        </p>
        <p>
            <span>关联申报</span>
            <em v-if="reimbDetail.declareId">{{
                reimbDetail.customerName +
                    '的' +
                    reimbDetail.typeStr +
                    '申报' || '- -'
            }}</em>
            <em v-else>- -</em>
        </p>
        <p>
            <span>报销金额</span>
            <em>{{ reimbDetail.expenseAmount || '- -' }}</em>
        </p>
    </div>
</template>

<script>
import { declareUpdate, declareGet } from '@/api/cost/declare.js';
import { contracGet } from '@/api/contr/contr.js';
export default {
    name: '',

    data() {
        return {
            btnP: {},
            update: false,
            form: {
                payWay: '',
            },
            routePath: this.$route.path,
            reimbDetail: {},
        };
    },
    components: {},
    created() {},
    methods: {
        returnName() {
            var str = '';
            this.reimbDetail.departmentAdminDOList.forEach((item) => {
                if (item.main == 1) {
                    str = item.departmentName;
                }
            });
            return str;
        },
        getData(data) {
            this.reimbDetail = data;
        },
        toUpdate() {
            this.update = !this.update;
            if (this.update) {
                this.form.payWay =
                    JSON.parse(JSON.stringify(this.declareDetail.payWay)) || '';
            }
        },
        declareGet(id) {
            let data = {
                param: {
                    id,
                },
            };
            return declareGet(data);
        },
        async declareUpdate() {
            let res = await this.declareGet(this.declareDetail.id);
            if (res.code == 200) {
                if (res.data.status == 4) {
                    let data = {
                        param: {
                            id: this.declareDetail.id,
                            payWay: this.form.payWay,
                            status: this.declareDetail.status,
                        },
                    };
                    declareUpdate(data).then((res) => {
                        if (res.code == 200) {
                            this.declareDetail.payWay = this.form.payWay;
                            this.update = false;
                        }
                    });
                } else {
                    this.update = false;
                    return this.$message.error(
                        '关联申报已更新，无法变更支付方式'
                    );
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.declare_info {
    padding-bottom: 16px;
    p {
        flex: 1;
        color: #333333;
        display: flex;
        padding-left: 15px;
        align-items: center;
        span {
            color: #666666;
            display: block;
            width: 56px;
            white-space: nowrap;
            margin-right: 12px;
        }
        .status {
            border-radius: 13px;
            border: 1px solid #2370eb;
            color: #2370eb;

            width: 64px;
            height: 24px;
            display: block;
            flex: none;
            text-align: center;
            line-height: 24px;
        }
        em {
            font-weight: 600;
            font-style: normal;

            i {
                font-size: 16px;
                color: #2370eb;
                cursor: pointer;
                vertical-align: -1px;
                margin-left: 10px;
            }
        }
        .el-select {
            margin-top: -5px;
            margin-bottom: 0;
        }
        button {
            height: 25px;
            line-height: 0px;
            padding: 5px 12px;
            margin-left: 5px;
        }

        button + button {
            margin-left: 5px;
        }
    }

    p + p {
        margin-top: 13px;
    }
}
</style>
