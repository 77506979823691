<template>
    <div class="declare_info">
        <p>
            <span>所在部门</span>
            <!-- <em>{{ applyInfo.departmentName || '- -' }}</em> -->
            <em v-if="applyInfo.departmentAdminDOList">
                <el-tooltip placement="top">
                    <div slot="content">
                        <p
                            style="line-height: 2"
                            v-for="item in applyInfo.departmentAdminDOList"
                            :key="item.id"
                        >
                            {{ item.departmentName }}
                        </p>
                    </div>
                    <em>{{ returnName() }}</em>
                </el-tooltip>
            </em>
        </p>
        <p>
            <span>申请时间</span>
            <em>{{ applyInfo.createTime || '- -' }}</em>
        </p>
        <p>
            <span>申请状态</span>
            <em class="status">{{
                applyInfo.status == 1
                    ? '待审批'
                    : applyInfo.status == 2
                    ? '已通过'
                    : applyInfo.status == 3
                    ? '已驳回'
                    : applyInfo.status == 4
                    ? '已撤销'
                    : applyInfo.status == 5
                    ? '已开票'
                    : applyInfo.status == 6
                    ? '已作废'
                    : ''
            }}</em>
        </p>
        <p>
            <span>合同编号</span>
            <em>{{ applyInfo.contractNo || '- -' }}</em>
        </p>
        <p>
            <span>客户名称</span>
            <em>{{ applyInfo.companyName || '- -' }}</em>
        </p>
        <p>
            <span>合同金额</span>
            <em>{{ applyInfo.contractAmount || '- -' }}</em>
        </p>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
            applyInfo: {},
        };
    },
    components: {},
    created() {},
    methods: {
        returnName() {
            var str = '';
            this.applyInfo.departmentAdminDOList.forEach((item) => {
                if (item.main == 1) {
                    str = item.departmentName;
                }
            });
            return str;
        },
        getData(data) {
            this.applyInfo = data;
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.declare_info {
    margin-bottom: 16px;
    p {
        flex: 1;
        color: #333333;
        display: flex;
        padding-left: 15px;
        align-items: center;
        span {
            color: #666666;
            display: block;
            width: 56px;
            white-space: nowrap;
            margin-right: 28px;
        }
        em {
            font-weight: 600;
            font-style: normal;
            flex: 1;
            i {
                font-size: 16px;
                color: #2370eb;
                cursor: pointer;
                vertical-align: -1px;
                margin-left: 10px;
            }
        }
        .status {
            border-radius: 13px;
            border: 1px solid #2370eb;
            color: #2370eb;

            width: 64px;
            height: 24px;
            display: block;
            flex: none;
            text-align: center;
            line-height: 24px;
        }
        .el-select {
            margin-top: -5px;
            margin-bottom: 0;
        }
        button {
            height: 25px;
            line-height: 0px;
            padding: 5px 12px;
            margin-left: 5px;
        }

        button + button {
            margin-left: 5px;
        }
    }

    p + p {
        margin-top: 13px;
    }
}
</style>
