import http from '@/utils/http.js'

// 列表
export function expenseList(data) {
    return http({
        url: "/jasoboss/business/expense/list",
        method: "post",
        data
    })
}
// 列表
export function expenseGet(data) {
    return http({
        url: "/jasoboss/business/expense/get",
        method: "post",
        data
    })
}
// 添加
export function reimbAdd(data) {
    return http({
        url: "/jasoboss/business/expense/add",
        method: "post",
        data
    })
}
// 修改
export function reimbUpdate(data) {
    return http({
        url: "/jasoboss/business/expense/update",
        method: "post",
        data
    })
}
// 审核
export function reimbAudit(data) {
    return http({
        url: "/jasoboss/business/expense/audit/add",
        method: "post",
        data
    })
}
// 统计
export function getBrief(data) {
    return http({
        url: "/jasoboss/business/expense/brief",
        method: "post",
        data
    })
}
// 添加评论
export function addComment(data) {
    return http({
        url: "/jasoboss/business/expense/comment/add",
        method: "post",
        data
    })
}
// 评论列表
export function commentList(data) {
    return http({
        url: "/jasoboss/business/expense/comment/list",
        method: "post",
        data
    })
}
// 评论删除
export function commentDelete(data) {
    return http({
        url: "/jasoboss/business/expense/comment/delete",
        method: "post",
        data
    })
}