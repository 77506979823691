<template>
    <div class="detailed">
        <div v-for="(item, index) in expenseDetailList" :key="item.id">
            <div>
                <p>
                    <span>备注</span>
                    <em style="display: block"> {{ item.remark || '- -' }} </em>
                </p>
            </div>
            <div>
                <p
                    v-if="
                        item.expenseDetailAttachmentBOList &&
                        item.expenseDetailAttachmentBOList.length > 0
                    "
                >
                    <span>支付凭证</span>
                    <em
                        v-for="itm in item.expenseDetailAttachmentBOList"
                        :key="itm.attachmentUrl"
                        v-if="itm.attachmentUrl"
                        class="img_box"
                        @click="
                            openFile(
                                itm.attachmentUrl,
                                itm.attachmentName.split('.')[1]
                            )
                        "
                    >
                        <i
                            v-if="
                                itm.attachmentName.split('.')[1] == 'pdf' ||
                                itm.attachmentName.split('.')[1] == 'PDF'
                            "
                        >
                            <img src="@/img/pdfUrl.png" alt="" />
                        </i>
                        <i
                            v-if="
                                itm.attachmentName.split('.')[1] == 'doc' ||
                                itm.attachmentName.split('.')[1] == 'docx'
                            "
                        >
                            <img src="@/img/word.png" alt="" />
                        </i>
                        <i
                            v-if="
                                itm.attachmentName.split('.')[1] == 'jpg' ||
                                itm.attachmentName.split('.')[1] == 'jpeg' ||
                                itm.attachmentName.split('.')[1] == 'png' ||
                                itm.attachmentName.split('.')[1] == 'PNG'
                            "
                        >
                            <img :src="itm.attachmentUrl" alt="" />
                        </i>
                    </em>
                    <em v-else>- -</em>
                </p>
                <p v-else>
                    <span>支付凭证</span>
                    <em>- -</em>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: '',

    data() {
        return {
            allMoney: 0,
            actualTotalAmount: 0,
            expenseDetailList: [],
        };
    },
    watch: {
        // declareDetail: {
        //   deep: true,
        //   handler(val) {
        //     if (val.id) {
        //       this.allMoney = 0;
        //       val.declareDetailList.forEach((item) => {
        //         this.allMoney += item.declareAmount;
        //       });
        //     }
        //   },
        // },
    },
    components: {},
    created() {},
    methods: {
        getData(data) {
            this.expenseDetailList = data;
        },
        openFile(url, type) {
            if (
                type == 'png' ||
                type == 'jpg' ||
                type == 'jpeg' ||
                type == 'PNG' ||
                type == 'JPG' ||
                type == 'JPEG'
            ) {
                window.open(url);
                return;
            }
            window.open(
                'https://view.officeapps.live.com/op/view.aspx?src=' + url
            );
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.detailed {
    > div {
        div {
            margin-bottom: 16px;
            display: flex;
            font-size: 14px;
            p {
                flex: 1;
                color: #333333;
                display: flex;
                padding-left: 15px;
                span {
                    color: #666666;
                    display: block;
                    width: 56px !important;
                    white-space: nowrap;
                    margin-right: 12px;
                }
                em {
                    flex: 1;
                    font-style: normal;
                    font-weight: 600;
                    img {
                        margin-right: 16px;
                        cursor: pointer;
                    }
                }
            }
        }
        > div + div {
            margin-top: 13px;
        }
    }
    .detail_title {
        border: 1px dashed rgba($color: #feb516, $alpha: 0.65);
        background-color: #fff0d0;
        margin-top: 16px;
        height: 30px;
        padding-left: 15px;
        border-radius: 2px;
        line-height: 30px;
        color: #333;
        margin-bottom: 13px;
    }

    .img_box {
        img {
            width: 100px;
            height: 100px;
        }
    }
}
</style>
