<template>
    <div class="audit_box">
        <div class="detail_box">
            <div style="background: #fff; padding: 14px 34px 20px 20px">
                <p class="detail_title">
                    {{ invoiceDetail.adminName }}提交的申报申请
                </p>
                <Apply ref="apply"></Apply>
                <p class="detail_title invoice_info">发票信息</p>
                <Invoice ref="invoice"></Invoice>
            </div>
        </div>
    </div>
</template>

<script>
import { contracGet } from '@/api/contr/contr.js';
import Apply from './components/apply.vue';
import Invoice from './components/invoice.vue';
export default {
    name: '',
    props: {},
    data() {
        return {
            invoiceDetail: {},
            auditData: {},
            comment: '',
            commentData: [],
            adminId: sessionStorage.getItem('adminId'),
        };
    },
    watch: {},
    components: {
        Apply,
        Invoice,
    },
    created() {},
    methods: {
        async getData(data) {
            console.log(data);
            this.invoiceDetail = data;
            setTimeout(() => {
                this.$refs.apply.getData(data);
                this.$refs.invoice.getData(data);
            }, 0);
            // if (data.contractId) {
            // }
            // let res = await this.contracGet(data.contractId);
            // this.declareDetail.contract = res.data;
            // setTimeout(() => {
            //     this.$refs.detailed.getData();
            // }, 0);
            // this.getCommentList(data.id);
        },
        contracGet(id) {
            let data = {
                param: {
                    id,
                },
            };
            return contracGet(data);
        },
        onClose() {
            this.auditData = {};
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}

.audit_box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .detail_box {
        flex: 1;
        display: flex;
        overflow: auto;
        flex-direction: column;
    }
    > div {
        font-size: 14px;
    }
    .detail_title {
        margin-bottom: 8px;
        background: #e9f2ff;
        border-radius: 2px;
        padding-left: 15px;
        font-size: 14px;
        border: 1px dashed rgba(35, 112, 235, 0.65);
        height: 30px;
        line-height: 30px;
        color: #333;
    }
    .invoice_info {
        border: 1px dashed rgba($color: #feb516, $alpha: 0.65);
        background-color: #fff0d0;
        margin-top: 16px;
    }
    .bottom-but {
        width: 100%;
        height: 48px;
        background: #fff;
        display: flex;
        padding: 0;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        border-top: 1px solid #e5e5e5;
    }
}
.comment_text {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}
</style>
