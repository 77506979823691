<template>
    <div class="audit_box">
        <div style="flex: 1; overflow-y: auto">
            <p class="detail_title">
                {{ reimbDetail.adminName }}提交的报销申请
            </p>
            <ReimbInfo ref="reimbInfo"></ReimbInfo>
            <p class="detail_title mingxi">报销凭证</p>
            <Detailed ref="detailed"></Detailed>
            <p class="detail_title audit_remark">审批备注</p>
            <AuditRemark ref="auditRemark"></AuditRemark>
        </div>
        <!-- <div slot="footer" class="bottom-but">
            <el-button
                @click="onClose"
                round
                style="
                    padding: 7px 20px;
                    width: 80px;
                    height: 26px;
                    border-radio: 26px;
                "
                size="mini"
                >取 消</el-button
            >
            <el-button
                type="primary"
                style="
                    margin-right: 15px;
                    background: #2370eb;
                    padding: 7px 20px;
                    width: 80px;
                    height: 26px;
                    border-radio: 26px;
                "
                round
                size="mini"
                @click="onReimbAudit2"
                >处 理</el-button
            >
        </div> -->
    </div>
</template>

<script>
import { declareAudit } from '@/api/cost/declare.js';
import ReimbInfo from './components/reimbInfo.vue';
import Detailed from './components/detailed.vue';
import AuditRemark from './components/auditRemark.vue';
import { declareGet } from '@/api/cost/declare.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            reimbDetail: {},
            auditData: {},
            status: '',
        };
    },
    watch: {},
    components: {
        ReimbInfo,
        Detailed,
        AuditRemark,
    },
    created() {},
    methods: {
        async getData(data) {
            this.reimbDetail = data;
            data.typeStr = '';
            if (data.declareId) {
                let res = await this.declareGet(data.declareId);
                if (res.code == 200) {
                    res.data.declareDetailList.forEach((item, index) => {
                        if (index == res.data.declareDetailList.length - 1) {
                            data.typeStr += this.$cost.declareDetailType(
                                item.type
                            );
                        } else {
                            data.typeStr +=
                                this.$cost.declareDetailType(item.type) + '、';
                        }
                    });
                    console.log(data.typeStr);
                }
            }
            setTimeout(() => {
                this.$refs.reimbInfo.getData(this.reimbDetail);
                this.$refs.detailed.getData(this.reimbDetail.expenseDetailList);
                if (data.expenseAuditList && data.expenseAuditList.length > 0) {
                    this.$refs.auditRemark.getData(
                        this.reimbDetail.expenseAuditList
                    );
                }
            }, 0);
        },
        declareGet(id) {
            let data = {
                param: {
                    id,
                },
            };
            return declareGet(data);
        },
        onReimbAudit2() {
            this.$emit('onReimbAudit2', this.reimbDetail);
        },
        onClose() {
            this.status = '';
            this.auditData = {};
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.audit_box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .line {
        height: 9px;
        background: #f9f9f9;
        width: 480px;
        margin-left: -20px;
    }
    > div {
        padding: 14px 34px 0 20px;
        font-size: 14px;
    }
    .detail_title {
        margin-bottom: 8px;
        background: #e9f2ff;
        border-radius: 2px;
        padding-left: 15px;
        font-size: 14px;
        border: 1px dashed rgba(35, 112, 235, 0.65);
        height: 30px;
        line-height: 30px;
        color: #333;
    }
    .audit_remark {
        background: #e5f5dc;
        border-color: rgba($color: #9fc877, $alpha: 0.65);
    }
    .mingxi {
        border: 1px dashed rgba($color: #feb516, $alpha: 0.65);
        background-color: #fff0d0;
    }
    .audit {
        background: #fff;
        margin-top: 16px;
        border: 1px dashed rgba($color: #fff, $alpha: 0.65);
        font-weight: 600;
    }
    .bottom-but {
        width: 100%;
        height: 48px;
        background: #fff;
        display: flex;
        padding: 0;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        border-top: 1px solid #e5e5e5;
    }
}
.el-form {
    padding-left: 20px;
}
.el-button--primary.is-disabled {
    color: #fff;
    background-color: #999;
    border-color: #999;
}
.el-button--primary {
    background-color: #2370eb;
    border-color: #2370eb;
    color: #fff;
}
</style>
